<template>
    <div class="createMessage page-container">
        <el-breadcrumb class="crumb" separator="/">
            <span @click="goBack" class="go-back-icon"><i class="el-icon-arrow-left"/></span>
            <el-breadcrumb-item :to="{ path: '/messageManage' }" class="bucket-crumb">消息管理</el-breadcrumb-item>
            <el-breadcrumb-item class="bucket-crumb">发布消息</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="left"
            ref="form">
            <el-form-item label="资源池" required>
                {{ points.find(i => i.regionId === regionId)?.regionName }}
            </el-form-item>
            <el-form-item label="消息类型" required prop="type">
                <el-radio
                    v-model="form.type"
                    :label="item.code"
                    v-for="item in messageType.slice(1)"
                    :key="item.code">{{ item.type }}
                </el-radio>
            </el-form-item>
            <el-form-item label="消息标题" prop="title">
                <el-input
                    placeholder="请输入消息标题"
                    v-model="form.title"
                    clearable></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="message">
                <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.message"></el-input>
                <div class="tips">行缩进请输入全角空格，换行请输入回车符</div>
            </el-form-item>
            <el-form-item label="接收方式" prop="sendtype">
                <el-radio-group v-model="form.sendtype">
                    <el-radio label="IN_STATION_MESSAGE">站内消息</el-radio>
                    <el-radio label="MAIL_MESSAGE">邮件消息</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="接收人" required>
                <el-radio v-model="form.receivetype" label="0">全体用户</el-radio>
                <el-radio v-model="form.receivetype" label="1">部分用户</el-radio>
            </el-form-item>
            <el-form-item label="" prop="accountIdList" v-if="form.receivetype == '1'">
                <el-select
                    v-model="form.accountIdList"
                    filterable
                    multiple
                    placeholder="请选择">
                    <el-option
                        v-for="item in accountList"
                        :key="item.bid"
                        :label="item.username"
                        :value="item.bid">
                    </el-option>
            </el-form-item>
            <el-form-item label="">
                <el-button type="primary" v-loading="loading" @click="publish()">发布</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import {messageType} from "@/utils/constant";
import storage from "@/utils/storage";

export default {
    name: 'createMessage',
    data() {
        return {
            loading: false,
            messageType,
            form: {
                type: '',
                title: '',
                message: '',
                sendtype: '',
                receivetype: '0',
                accountIdList: '',
            },
            rules: {
                title: [{required: true, message: '消息标题不能为空', trigger: 'blur'}, {max:100, message: '最多输入100个字符'}],
                message: [{required: true, message: '消息内容不能为空', trigger: 'blur'}, {max:200, message: '最多输入200个字符'}],
                sendtype: [{required: true, message: '接收方式不能为空', trigger: 'change'}],
                type: [{required: true, message: '请选择消息类型', trigger: 'change'}],
                accountIdList: [
                    {
                        required: true,
                        message: '接收人为部分用户的场合，接收人不能为空',
                        trigger: 'blur',
                    },
                ],
            },
            accountList: [

            ],
            regionId: ''
        }
    },
    created() {
        this.listAccountUser()
        this.regionId = storage.get('REGION_ID') || ''
    },
    watch: {

    },
    computed: {
        ...mapGetters(['userData', 'points'])
    },
    methods: {
        listAccountUser () {
            this.$postJson('/admin/listAccountUser', {subType: '0', role: 'ROLE_USER'})
                .then(res => {
                    this.accountList = res.data
                })
        },
        goBack() {
            this.$router.push({name: 'messageManage'})
        },
        publish() {
            if (this.loading) {
                return
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let receivingAccount = this.form.receivetype === '0' ? 'ALL' : 'PART'
                    let data = {
                        type: this.form.type,
                        title: this.form.title,
                        content: this.form.message,
                        receivingMode: this.form.sendtype,
                        receivingAccount,
                        regionId: '224'
                    }
                    if (receivingAccount === 'PART') {
                        data.accountIdList = this.form.accountIdList
                    }
                    this.loading = true
                    this.$postJson('/message/publish', data)
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success('发布成功')
                            this.goBack()
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
                    // this.createMessage({...this.form, sendtype: sendtype})
                    //     .then((res) => {
                    //     this.$message.success('发布成功')
                    //     this.goBack()
                    // })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.createMessage {
    padding: 24px 32px;
    background: #fff;

    .el-input {
        width: 470px;
    }

    .el-textarea {
        width: 470px;

        /deep/ {
            .el-textarea__inner {
                min-height: 120px !important;
            }
        }
    }

    .tips {
        color: #9d9d9d;
    }
}
</style>
